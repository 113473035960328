import mapboxMixins from "@/core/mixins/mapbox.mixins";

let rasterLayerMixin = {
  mixins: [mapboxMixins],
  methods: {
    addRasterLayer(
      layerType,
      layerId,
      step,
      zIndex,
      accessToken,
      type = "raster"
    ) {
      const domain = process.env.VUE_APP_RASTLESS_URL;
      // const name = this.getMapboxLayerName(layerId, type);
      const sourceName = `${layerType}Source`;
      const mapboxLayerName = `${layerType}Layer`;
      let url = `${domain}/layers/${layerId}/${step.datetime}/tile/{z}/{x}/{y}.png?token=${accessToken}`;
      if (type === "raster-dem") {
        url += "&terrain=true";
      }
      if (layerType != "background") {
        this.hideTerrain(this.$store.visualizeMap);
      }
      this.$store.visualizeMap.U.removeSource(sourceName);
      this.$store.visualizeMap.U.addRasterSource(sourceName, {
        type: type,
        tiles: [url],
        tileSize: 256
      });
      if (type !== "raster-dem") {
        this.$store.visualizeMap.U.addRasterLayer(
          mapboxLayerName,
          sourceName,
          {},
          zIndex
        );
      }
    },

    removeRasterLayer(source) {
      this.$store.visualizeMap.U.removeSource(source);
    },

    aggregateRasterLayer(
      layerType,
      layerId,
      firstStep,
      withinDate,
      tempResolution,
      zIndex,
      accessToken
    ) {
      const domain = process.env.VUE_APP_RASTLESS_URL;
      const sourceName = `${layerType}Source`;
      const mapboxLayerName = `${layerType}Layer`;
      this.$store.visualizeMap.U.removeSource(sourceName);
      this.$store.visualizeMap.U.addRasterSource(sourceName, {
        tiles: [
          `${domain}/aggregate/${layerId}/tile/{z}/{x}/{y}.png?date_startswith=${withinDate}&token=${accessToken}`
        ],
        tileSize: 256
      });
      this.$store.visualizeMap.U.addRasterLayer(
        mapboxLayerName,
        sourceName,
        {},
        zIndex
      );
    },
    aggregateRasterLayerByRange(
      layerType,
      layerId,
      startDate,
      endDate,
      tempResolution,
      zIndex,
      accessToken
    ) {
      const domain = process.env.VUE_APP_RASTLESS_URL;
      const sourceName = `${layerType}Source`;
      const mapboxLayerName = `${layerType}Layer`;
      this.$store.visualizeMap.U.removeSource(sourceName);
      this.$store.visualizeMap.U.addRasterSource(sourceName, {
        tiles: [
          `${domain}/aggregate/${layerId}/tile/{z}/{x}/{y}.png?start_date=${startDate}&end_date=${endDate}&token=${accessToken}`
        ],
        tileSize: 256
      });
      this.$store.visualizeMap.U.addRasterLayer(
        mapboxLayerName,
        sourceName,
        {},
        zIndex
      );
    },
    async zoomToTimestep(layerId, datetime) {
      const response = await this.$rastless.get(
        `/layers/${layerId}/steps/${datetime}?token=${this.accessToken}`
      );
      this.$store.visualizeMap.fitBounds(response.data.bbox, {
        padding: 100
      });
    },
    async fetchLayerStepRange(layerId) {
      const response = await this.$rastless.get(
        `/layers/${layerId}/steps-range`
      );
      return response.data;
    },
    async fetchAllTimesteps(
      layerId,
      details = false,
      accessToken = this.accessToken
    ) {
      let response = await this.$rastless.get(
        `/layers/${layerId}/steps?details=${details}&token=${accessToken}`
      );
      return response.data;
    }
  }
};

export default rasterLayerMixin;
