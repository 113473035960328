<template>
  <map-compare
    v-if="$appConfig.keycloakClient === 'coasts'"
    @mapLoaded="addLayer"
  ></map-compare>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import vectorLayerMixin from "@/core/mixins/vectorLayer.mixin";
import MapCompare from "@/core/views/MapCompare.vue";

export default {
  name: "Analyse",
  components: { MapCompare },
  mixins: [rasterLayerMixin, vectorLayerMixin],
  data: () => ({
    showLabels: false
  }),
  computed: {
    ...mapState("raster", ["activeRasterLayer"]),
    ...mapState("vector", ["activeVectorLayer"]),
    ...mapState("management", ["accessToken"]),
    ...mapGetters("draw", ["virtualStationsByRegion"])
  },
  methods: {
    addLayer() {
      this.addRaster();
      this.addVectorLayer();
      this.addVirtualStations();
    },
    addRaster() {
      if (this.activeRasterLayer) {
        const layer = this.activeRasterLayer;
        const step = {
          bbox: layer.bbox,
          datetime: layer.datetime
        };
        this.addRasterLayer(
          "raster",
          layer.layerId,
          step,
          "z-index-2",
          this.accessToken
        );
      }
    },
    addVectorLayer() {
      if (this.activeVectorLayer.layerId) {
        // const layer = this.activeVectorLayer;
        this.addVector(this.activeVectorLayer);
        this.toggleLabels();
      }
    },
    addVirtualStations() {
      this.virtualStationsByRegion.forEach(station => {
        const feature = {
          id: station.id,
          type: "Feature",
          properties: {},
          geometry: station.geometry
        };
        this.$store.draw.add(feature);
      });
    }
  }
};
</script>

<style scoped></style>
