<template>
  <v-main>
    <div class="comparison-map">
      <div id="left" class="map align-left"></div>
      <div id="right" class="map align-self-end"></div>
      <v-overlay
        v-model="showOverlay"
        class="align-center justify-center"
        contained
      >
        <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card
        width="260"
        height="68px"
        elevation="0"
        v-if="activeLayer1.colormap"
        class="meta-data-image-coasts "
      >
        <img
          alt="legend"
          v-if="legendImage"
          class="legend-image-coasts"
          :src="legendImage"
        />
      </v-card>
    </div>
  </v-main>
</template>

<script>
import mapbox from "mapbox-gl";
import MapboxConfig from "@/core/plugins/mapbox";
import bbox from "@turf/bbox";
import regionMixin from "@/core/mixins/region.mixin";
import MapboxCompare from "mapbox-gl-compare";
import { mapState } from "vuex";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import MapboxUtils from "mapbox-gl-utils";
import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import mapboxMixins from "@/core/mixins/mapbox.mixins";

export default {
  name: "MapCompare",
  mixins: [regionMixin, coastLayerMixin, mapboxMixins],
  data: () => ({
    showOverlay: false,
    mapIsLoading: true,
    map: null,
    compareMap: null,
    baseUrl: process.env.VUE_APP_BASE_URL,
    activeSensor: null,
    analysisStation: true,
    selectedStation: {},
    legendImage: null
  }),
  computed: {
    ...mapState("analyse", ["activeLayer1", "activeLayer2"]),
    ...mapState("management", ["accessToken"]),
    mapStyle() {
      return this.getMapStyle();
    },
    boundingBox() {
      if (this.activeRegion) {
        return bbox(this.activeRegion);
      } else {
        return null;
      }
    }
  },
  methods: {
    addRaster(
      map,
      layerType,
      layerId,
      step,
      zIndex,
      accessToken,
      type = "raster"
    ) {
      const domain = process.env.VUE_APP_RASTLESS_URL;
      const sourceName = `${layerType}Source`;
      const mapboxLayerName = `${layerType}Layer`;
      let url = `${domain}/layers/${layerId}/${step.datetime}/tile/{z}/{x}/{y}.png?token=${accessToken}`;
      if (map.getLayer(mapboxLayerName)) {
        map.removeLayer(mapboxLayerName);
      }
      if (map.getSource(sourceName)) {
        map.removeSource(sourceName);
      }
      map.U.addRasterSource(sourceName, {
        type: type,
        tiles: [url],
        tileSize: 256,
        bounds: step.bbox
      });
      map.U.addRasterLayer(mapboxLayerName, sourceName, {}, zIndex);
    },
    initMap(id) {
      mapbox.accessToken = MapboxConfig.accessToken;
      const initMap = new mapbox.Map({
        container: id,
        bounds: this.boundingBox,
        style: this.mapStyle,
        transformRequest: url => {
          if (url.startsWith(this.baseUrl) && this.$keycloak.authenticated) {
            return {
              url: url,
              headers: { Authorization: `Bearer ${this.$keycloak.token}` }
            };
          }
        }
      });
      const mapPromise = new Promise(resolve => {
        initMap.on("load", () => resolve(initMap));
      });

      return { initMap, mapPromise };
    },
    setLegendImage(layerId, layerStep) {
      this.legendImage = `${process.env.VUE_APP_RASTLESS_URL}/legend?layer_id=${layerId}&layer_step=${layerStep}&orientation=horizontal`;
    }
  },
  watch: {
    activeLayer1() {
      if (this.activeLayer1.colormap) {
        this.setLegendImage(
          this.activeLayer1.layerId,
          this.activeLayer1.datetime
        );
      }
      if (this.map) {
        this.addRaster(
          this.map,
          "raster",
          this.activeLayer1.layerId,
          this.activeLayer1,
          "z-index-1",
          this.accessToken
        );
      }
    },
    activeLayer2() {
      if (this.compareMap) {
        this.addRaster(
          this.compareMap,
          "raster",
          this.activeLayer2.layerId,
          this.activeLayer2,
          "z-index-1",
          this.accessToken
        );
      }
    }
  },
  mounted() {
    if (bbox) {
      this.before = this.initMap("left");
      this.after = this.initMap("right");
      //

      this.mapPromise = this.before.mapPromise;
      this.mapPromise.then(mapObject => {
        MapboxUtils.init(mapObject, mapbox);
        this.map = mapObject;
        this.addRaster(
          this.map,
          "raster",
          this.activeLayer1.layerId,
          this.activeLayer1,
          "z-index-1",
          this.accessToken
        );
      });

      this.compareMapPromise = this.after.mapPromise;
      this.compareMapPromise.then(mapObject => {
        MapboxUtils.init(mapObject, mapbox);
        this.compareMap = mapObject;
        this.addRaster(
          this.compareMap,
          "raster",
          this.activeLayer2.layerId,
          this.activeLayer2,
          "z-index-1",
          this.accessToken
        );
      });
      if (this.activeLayer1.colormap) {
        this.setLegendImage(
          this.activeLayer1.layerId,
          this.activeLayer1.datetime
        );
      }
      setTimeout(() => {
        const compare = new MapboxCompare(
          this.before.initMap,
          this.after.initMap,
          "div.comparison-map",
          {
            orientation: "vertical"
          }
        );

        compare.setSlider("700");
      }, 100);
    }
  }
};
</script>

<style scoped>
#comparison-maps {
  width: 100%;
  height: 100%;
  position: relative;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.legend-image-coasts {
  width: 240px;
  padding: 5px;
  z-index: 2;
}

.meta-data-image-coasts {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 0 0 0 0;
  background-color: rgba(250, 250, 250, 0.5);
  padding: 5px;
  z-index: 2;
}
</style>
